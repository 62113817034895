import React, {Component} from 'react';

import { NavDropdown, Nav, Navbar, Container } from 'react-bootstrap';

import { FaHome, FaInfo, FaSearch, FaBook, FaAdjust, FaPeopleCarry, FaPencilAlt, FaPhoneAlt, FaPhone, FaMobile, FaMobileAlt, FaMap, FaMapMarked, FaInternetExplorer,
    FaFileAlt, FaFileArchive, FaBuilding, FaTv, FaPencilRuler, FaYoutube, FaFacebookMessenger, FaFacebookSquare } from 'react-icons/fa';
  
import 'bootstrap/dist/css/bootstrap.min.css';

export class Navegador extends Component{
    render(){
        return(
            <div>
                <Navbar expand="xl" bg="primary" variant="dark">
  <Container fluid>
    <Navbar.Brand href="#home">Jorge Alva - Carmen Ortiz</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="/" active>Home</Nav.Link>
        <Nav.Link href="/Mapa" active>Mapa</Nav.Link>
        
        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown> */}
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
            </div>
        )
    }

}