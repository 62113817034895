import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBPopover, MDBPopoverBody,
MDBPopoverHeader, MDBTooltip } from 'mdbreact';
import './catalogo2.css';


export class Datos extends Component{
    state = {
        show_seccion: true,
    }

    toggle_seccion = () =>{
        this.setState({
            show_seccion: !this.state.show_seccion,
        })
    }
  render() {
    if (this.state.show_seccion) {
        return (
            <div class="Datos">
                <h5>Aquí se muestran los datos</h5>
                <button onClick={this.toggle_seccion}>Toggle contenido</button>
            </div>
        )     
    }
    else{
        return (
            <div class="Datos1">
                <h5>Pica en cualquier punto para ver sus parametros almacenados</h5>
                <button onClick={this.toggle_seccion}>Toggle contenido</button>
            </div>
        )   
    }
    
  }
}