import logo from './logo.svg';
import './App.css';
import {Inicio} from './components/Home/Inicio';
import {Mapa} from './components/GISFIC/Mapa';
import {Navegador} from './components/Navegator/Navegador';
import { Error404 } from './components/Error404/Error404';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div>
        <Navegador/>
      </div>
      <div>
        <BrowserRouter>
          <Routes className="">
            <Route path='*' element={<Error404/>} />
            <Route path='/' element={<Inicio/>} />
            <Route path='/Mapa' element={<Mapa/>} />
          </Routes>
        
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
