
import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row'
import {Popover, OverlayTrigger, Button, Card, Navbar, Nav, NavDropdown, ListGroup, FormGroup} from 'react-bootstrap';
//externals
import departament from './depas';
import ReactDOM from 'react-dom';

//Stilos
import ModalCaracteristicas from './modal_caracteristicas';
import "./catalogo2.css"
import { MDBPopover, MDBPopoverHeader, MDBPopoverBody, MDBTooltip, MDBContainer, MDBRow, MDBCol, MDBMask, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { FaHome, FaPhoneAlt, FaPhone, FaMobile, FaMobileAlt, FaMap, FaMapMarked, FaInternetExplorer,
  FaFileAlt, FaFileArchive, FaBuilding, FaTv } from 'react-icons/fa';
//Logos
import logo_masw from './masw.png';
import logo_mam from './mam.png';
import logo_perforacion from './perforacion.png';
import logo_microtremor from './microtremor.png';

import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import OverviewMap from 'ol/control/OverviewMap';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
//import ZoomSlider from 'ol/source/ZoomSlider';
//importar estilos
import Style from 'ol/style/Style';
import BingMaps from 'ol/source/BingMaps';
//import para vectores

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Text, Circle} from 'ol/style';
import BaseLayer from 'ol/layer/Base';

//importar para geolocalizar 
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';

//importar para popup
import Overlay from 'ol/Overlay';
import {Datos} from './cuadro_datos'

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf } from "react-icons/bs";
import { FiLayers } from "react-icons/fi";
import { MdZoomOutMap, MdFilterList } from "react-icons/md";
import {GiUnionJack, GiPositionMarker} from "react-icons/gi";

//import axios
import axios from 'axios';

//Geocode
import Geocode from "react-geocode";

//seccion banner detail
import BanerDetail from './banner2';

//SideBart
//import Sidebar from "react-sidebar";
require('ol/ol.css');



export class Mapa extends Component{
    state = {
        modal_ini: false,
        modal_detail: false,
        modal_leyend:false,
        show_datos_punto:true
      }
    
      toggle_datos_punto = () =>{
          this.setState({
              show_datos_punto: !this.state.show_datos_punto,
          })
      }
      toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
          [modalNumber]: !this.state[modalNumber]
        });
      }
    
        constructor(props) {
            super(props);
            this.state = {
              show_datos_punto: true,
              estado_capa_masw: 'Activo_masw',
              estado_capa_mam: 'Activo_mam',
              estado_capa_microtremor: 'Activo_microtremor',
              estado_capa_perforacion: 'Activo_perforacion',
              estado_capa_espectro_respuesta: 'Activo_espectrorespuesta',
              estado_capa_calicata: 'Activo_calicata',
              estado_capa_spt: 'Activo_spt', 
              estado_capa_nombre_masw: 'Activo',
              estado_capa_nombre_mam: 'Activo',
              estado_capa_nombre_microtremor: 'Activo',
              estado_capa_nombre_perforacion: 'Activo',
              estado_capa_nombre_calicata: 'Activo',
              estado_capa_nombre_spt: 'Activo', 
              tipo_ensayo:'',
              nombre_ensayo:'',
              departamento_ensayo:'',
              provincia_ensayo:'',
              distrito_ensayo:'',
              zona_ensayo: '',
              coordUTM1_ensayo:'',
              coordUTM2_ensayo:'',
              fuente_ensayo:'',
              periodo_ensayo: '',
              velcorte_ensayo:'',
              name_ini:'',
              proyecto:'',
              logo_ensayo:'',
              img1_ensayo: '',
              img2_ensayo:'',
              img3_ensayo:'',
              documentacion_ensayo:'',
              simbolo_ensayo:'',
              longitud_zoom:'',
              latitud_zoom:'',
              caracteristicas_puntos: 'sin_contenido_mapa_web_web',
              p_control: 'Control_Panel',
              base_images: 'https://microzonificacion-data.cid-allpanchis.org/media/',
              ruta_ensayos_masw: 'https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson',
              ruta_ensayos_mam: 'https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson',
              ruta_ensayos_microtremor: 'https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson',
              ruta_ensayos_perforacion: 'https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson',
              ruta_ensayos_espectrorespuesta: 'https://microzonificacion-data.cid-allpanchis.org/microzon/espectrorespuesta.geojson',
              ruta_ensayos_calicata: 'https://microzonificacion-data.cid-allpanchis.org/microzon/calicata.geojson',
              ruta_ensayos_spt: 'https://microzonificacion-data.cid-allpanchis.org/microzon/spt.geojson', 
              panel_filtros: 'sin_panel_filtro',
              tipo_filtro: 'none',
              state_query: '0',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
              //Estados Iniciales 
              departamento: 'Lima',
              provincia: 'Lima',
              distrito: 'Ancón',
              vs: '0-200',
              periodo: '0-0.1',
              radio: '1',
              estado_boton_geoposition:'Inactivo',
              boton_geoposition: 'Geo_Inactivo',
              seccion_marcadores: 'seccion_marcadores_oculto',
              seccion_lista: 'seccion_lista_oculto',
              seccion_mapas: 'seccion_mapas_oculto',
              text_ensayos_web: 'text_ensayos_web',
              text_ensayos_web_ma: 'text_ensayos_web',
              text_ensayos_web_mi: 'text_ensayos_web',
              text_ensayos_web_pe: 'text_ensayos_web',
              text_ensayos_web_ca: 'text_ensayos_web',
              text_ensayos_web_sp: 'text_ensayos_web',
              value:'',
              //seccion total
              cantidad_ensayos_totales:0,
              //tabla filtros
              ensayos_masw:[],
              cantidad_ensayos_masw:'',
              url_axios_masw:'https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson',
              loading_masw: true,
    
              ensayos_mam:[],
              cantidad_ensayos_mam:'',
              url_axios_mam:'https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson',
              loading_mam: true,
    
              ensayos_microtremor:[],
              cantidad_ensayos_microtremor:'',
              url_axios_microtremor:'https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson',
              loading_microtremor: true,
              
              ensayos_espectrorespuesta:[],
              cantidad_ensayos_espectrorespuesta:'',
              url_axios_espectrorespuesta:'https://microzonificacion-data.cid-allpanchis.org/microzon/espectrorespuesta.geojson',
              loading_espectrorespuesta: true,
              
              ensayos_perforacion:[],
              cantidad_ensayos_perforacion:'',
              url_axios_perforacion:'https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson',
              loading_perforacion: true,
    
              ensayos_calicata:[],
              cantidad_ensayos_calicata:'',
              url_axios_calicata:'https://microzonificacion-data.cid-allpanchis.org/microzon/calicata.geojson',
              loading_calicata: true,
    
              ensayos_spt:[],
              cantidad_ensayos_spt:'',
              url_axios_spt:'https://microzonificacion-data.cid-allpanchis.org/microzon/spt.geojson',
              loading_spt: true, 
    
              //activo tablas
              contenido_datos_masw: 'contenido_datos_activo',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo', 
              boton_control_uno:'botones_tablas_active',
              boton_control_dos:'botones_tablas',
              boton_control_tres:'botones_tablas',
              boton_control_cuatro:'botones_tablas',
              boton_control_cinco:'botones_tablas',
              boton_control_seis:'botones_tablas',
    
              //Detail Control´s
              Contenido_Detalle_Ensayo: 'Contenido_Detalle_Ensayo',
              Contenido_Imagenes_Ensayo: 'Contenido_Imagenes_Ensayo_Inactive',
              Contenido_Documentacion_Ensayo: 'Contenido_Documentacion_Ensayo_Inactive',
              control_detalle_boton1: 'Control_Detalle_Ensayo_boton_active',
              control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive',
              
              //
              lat_filter: -12.0917,
              lon_filter:	-77.0461,
    
              //filtros
              loading_Filtros: true,
              loading_Filtros_One: true,
              url_axios_Filtros: 'https://microzonificacion-data.cid-allpanchis.org/microzon/filtros',
              filtros_distrito:[],
              filtros_provincias: [],
            };
            this.cuando_cambia = this.cuando_cambia.bind(this);
            this.cuando_cambia_departamento = this.cuando_cambia_departamento.bind(this);
            this.cuando_cambia_provincia = this.cuando_cambia_provincia.bind(this);
            this.cuando_cambia_distrito = this.cuando_cambia_distrito.bind(this);
            this.cuando_cambia_vs = this.cuando_cambia_vs.bind(this);
            this.cuando_cambia_periodo = this.cuando_cambia_periodo.bind(this);
            this.cuando_cambia_radio = this.cuando_cambia_radio.bind(this);
            this.cuando_cambia_ciudad = this.cuando_cambia_ciudad.bind(this);
            this.cuando_cambia_ano = this.cuando_cambia_ano.bind(this);
            this.cuando_cambia_fuente = this.cuando_cambia_fuente.bind(this);
            this.cuando_filtra= this.cuando_filtra.bind(this);
            this.center_marker = this.center_marker.bind(this);
            this.cambiaTabla = this.cambiaTabla.bind(this);
            this.cambiaDetalle = this.cambiaDetalle.bind(this);    
            this.cambia_direccion = this.cambia_direccion.bind(this);
            this.cambia_lat_filter = this.cambia_lat_filter.bind(this);
            this.cambia_lon_filter = this.cambia_lon_filter.bind(this);
            this.busca_direccion = this.busca_direccion.bind(this);
    
            this.OSM = new TileLayer({
                source: new OSM(),
                visible: false
            });
            this.WaterColor = new TileLayer({
                source: new Stamen({
                  layer: 'watercolor',
                }),
                visible: false
            });
            //Estos TileLayers jalan los mapas de como se van a ver
            this.bingmap = new TileLayer({
                source: new BingMaps({
                    key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
                    imagerySet:'AerialWithLabelsOnDemand',//AerialWithLabelsOnDemand
                }),
                visible: false //Esto define si va a ser visible en el arranque o no y siempre el ultimo est[a encima ]
            });
            this.bingmapRoad = new TileLayer({
              source: new BingMaps({
                  key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
                  imagerySet:'Road',//AerialWithLabelsOnDemand
              }),
              visible: true
            });
            this.ByN = new TileLayer({
              source: new Stamen({
                layer: 'toner',
              }),
              visible: false
            });
            this.terreno = new TileLayer({
              source: new Stamen({
                layer: 'terrain',
              }),
              visible: false
            });
            this.Etiquetas = new TileLayer({
              source: new Stamen({
                layer: 'terrain-labels',
              }),
              visible: true
            });
            this.style = new Style({
                fill: new Fill({
                  color: 'rgba(215,215,215,0.3)'
                }),
                stroke: new Stroke({
                  color: 'rgba(115,115,115,0.95)',
                  width: 2
                }),
                text: new Text({
                  font: '12px Calibri,sans-serif',
                  fill: new Fill({
                    color: '#000',
                    width:5
                  }),
                  stroke: new Stroke({
                    color: 'black',
                    width: 5
                  })
                })
            });
            //Zonas microzonificación ------ estos tipo Style son los estilos, el fill es el relleno, el stroke es el borde
            this.stylezona1 = new Style({
              fill: new Fill({
                color: 'rgba(56,168,0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(56,168,0,0.95)',
                width: 2
              })
            });
            this.stylezona1a = new Style({
              fill: new Fill({
                color: 'rgba(181, 230, 29,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(181, 230, 29,0.95)',
                width: 2
              })
            });
            this.stylezona1b = new Style({
              fill: new Fill({
                color: 'rgba(193, 205, 54,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(193, 205, 54,0.95)',
                width: 2
              })
            });
            //estas zonas son para tacna los estilos d elos poligonos
            this.stylezona2 = new Style({
              fill: new Fill({
                color: 'rgba(255,255,0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255,255,0,0.95)',
                width: 2
              })
            });
            //estas zonas son para tacna los estilos d elos poligonos
            this.stylezona2a = new Style({
              fill: new Fill({
                color: 'rgba(252, 217, 20,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(252, 217, 20,0.95)',
                width: 2
              })
            });
            this.stylezona2b = new Style({
              fill: new Fill({
                color: 'rgba(227, 239, 3,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(227, 239, 3,0.95)',
                width: 2
              })
            });
            this.stylezona3 = new Style({
              fill: new Fill({
                color: 'rgba(255, 170, 0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255, 170, 0,0.95)',
                width: 2
              })
            });
            this.stylezona3a = new Style({
              fill: new Fill({
                color: 'rgba(238, 180, 4,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(238, 180, 4,0.95)',
                width: 2
              })
            });
            this.stylezona3b = new Style({
              fill: new Fill({
                color: 'rgba(217, 118, 26,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(217, 118, 26,0.95)',
                width: 2
              })
            });
            this.stylezona4 = new Style({
              fill: new Fill({
                color: 'rgba(255, 0, 0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255, 0, 0,0.95)',
                width: 2
              })
            });
            this.stylezona4a = new Style({
              fill: new Fill({
                color: 'rgba(203, 88, 39,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(203, 88, 39,0.95)',
                width: 2
              })
            });
            this.stylezona4b = new Style({
              fill: new Fill({
                color: 'rgba(180, 121, 63,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(180, 121, 63,0.95)',
                width: 2
              })
            });
            this.stylezona5 = new Style({
              fill: new Fill({
                color: 'rgba(255, 41, 227,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255, 41, 227,0.95)',
                width: 2
              })
            });
            this.stylezona5a = new Style({
              fill: new Fill({
                color: 'rgba(255,0,0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255,0,0,0.95)',
                width: 2
              })
            });
            this.stylezona5b = new Style({
              fill: new Fill({
                color: 'rgba(255,0,0,0.6)'
              }),
              stroke: new Stroke({
                color: 'rgba(255,0,0,0.95)',
                width: 2
              })
            });
            //Estos tipos VectorSource son de donde va a jalar la informaci[on, ahi se pone las urls]
            this.sourceZona1 = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona1.geojson',
              format: new GeoJSON()
            });
            this.sourceZona1a = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona1a.geojson',
              format: new GeoJSON()
            });
            this.sourceZona1b = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona1b.geojson',
              format: new GeoJSON()
            });
            this.sourceZona2 = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona2.geojson',
              format: new GeoJSON()
            });
            this.sourceZona2a = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona2a.geojson',
              format: new GeoJSON()
            });
            this.sourceZona2b = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona2b.geojson',
              format: new GeoJSON()
            });
            this.sourceZona3 = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona3.geojson',
              format: new GeoJSON()
            });
            this.sourceZona3a = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona3a.geojson',
              format: new GeoJSON()
            });
            this.sourceZona3b = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona3b.geojson',
              format: new GeoJSON()
            });
            this.sourceZona4 = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona4.geojson',
              format: new GeoJSON()
            });
            this.sourceZona4a = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona4a.geojson',
              format: new GeoJSON()
            });
            this.sourceZona4b = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona4b.geojson',
              format: new GeoJSON()
            });
            this.sourceZona5 = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona5.geojson',
              format: new GeoJSON()
            });
            this.sourceZona5a = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona5a.geojson',
              format: new GeoJSON()
            });
            this.sourceZona5b = new VectorSource({
              //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/zona5b.geojson',
              format: new GeoJSON()
            });


            //Estos son los sources para cada poligono de las zonas
            this.vectorZona1 = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona1,
              style: this.stylezona1,
              visible: true
            });
            this.vectorZona1a = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona1a,
              style: this.stylezona1a,
              visible: true
            });
            this.vectorZona1b = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona1b,
              style: this.stylezona1b,
              visible: true
            });
            this.vectorZona2 = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona2,
              style: this.stylezona2,
              visible: true
            });
            this.vectorZona2a = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona2a,
              style: this.stylezona2a,
              visible: true
            });
            this.vectorZona2b = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona2b,
              style: this.stylezona2b,
              visible: true
            });
            this.vectorZona3 = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona3,
              style: this.stylezona3,
              visible: true
            });
            this.vectorZona3a = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona3a,
              style: this.stylezona3a,
              visible: true
            });
            this.vectorZona3b = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona3b,
              style: this.stylezona3b,
              visible: true
            });
            this.vectorZona4 = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona4,
              style: this.stylezona4,
              visible: true
            });
            this.vectorZona4a = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona4a,
              style: this.stylezona4a,
              visible: true
            });
            this.vectorZona4b = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona4b,
              style: this.stylezona4b,
              visible: true
            });
            this.vectorZona5 = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona5,
              style: this.stylezona5,
              visible: true
            });
            this.vectorZona5a = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona5a,
              style: this.stylezona5a,
              visible: true
            });
            this.vectorZona5b = new VectorLayer({
              name: 'Countries',
              source: this.sourceZona5b,
              style: this.stylezona5b,
              visible: true
            });
            //Límites
            //estos son los vectores  para los poligonos
            this.sourceManual = new VectorSource({
              //url: 'httpsss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/departamentos_joseph.geojson',
              format: new GeoJSON()
            });
            this.vectorLayer = new VectorLayer({
              name: 'Countries',
              source: this.sourceManual,
              style: this.style,
              visible:false
            });
            this.sourceManual1 = new VectorSource({
              //url: 'httpsss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
              url: './peru-geojson-master/provincias_joseph.geojson',
              format: new GeoJSON()
            });
            this.vectorLayer1 = new VectorLayer({
              name: 'Countries',
              source: this.sourceManual1,
              style: this.style,
              visible:false
            });
            this.sourceManual2 = new VectorSource({
              url:'./peru-geojson-master/distritos_joseph_selected_lci_sm_ama_caj_lam_lali_areq_cu_ta_hu_aa_dm.geojson',
              format: new GeoJSON()
            });
            this.vectorLayer2 = new VectorLayer({
              name: 'Manual',
              source: this.sourceManual2,
              style: this.style,
              visible: false
            });
            //MASW  ----- aqui comienzan a definirse los estilos para los ensayos esto es de forma independiente
            this.fillMasw = new Fill({color: 'rgba(57,255,20,0.9)'});
            this.strokeMasw = new Stroke({color: 'rgba(0,0,0,0.9)',width: 1});
            this.styleMasw = new Style({
              image: new Circle({
                fill: this.fillMasw,
                stroke: this.strokeMasw,
                radius: 8
                }),
              //image: new Icon({
              //  anchor: [0.5, 46],
              //  anchorXUnits: 'fraction',
              //  anchorYUnits: 'pixels',
              //  src: logo_masw,
              //  src: logo_masw,
              //  src: 'httpsss://openlayers.org/en/latest/examples/data/icon.png',
              //  scale: 0.05,
              //}),
                fill:this.fillMasw,
                stroke: this.strokeMasw,
                text: new Text({
                //text: "Punto !!",
                font: 'bold 11px sans-serif red',
              }),
            });  
            //esta es el source para osea el geojson de donde jala para el ensayo masw
            this.sourceMasw = new VectorSource({
              /* features: (new GeoJSON()).readFeatures(
                {"type":"FeatureCollection","features": [
                  {"type": "Feature","geometry": {"type": "Point","coordinates": [-77.048, -12.0912]},"properties": {"Parametros": "Hipocentrales","Latitud": "-77.048","Longitud": "-12.0912","Ubicacion": "Lima","dep": "2","Magnitud": "5.6 ML","name": "Sismo de Lima","Reporte": "Instituto Geofísico del Perú","Fecha": "1917/06/16","Intensidad": "II Lima, III Ica","Imagen": "httpss://www.culture.gouv.fr/Wave/image/memoire/1599/sap40_d0004328_v.jpg"}}]},
                {featureProjection: 'EPSG:3857'}),
              format: new GeoJSON() */
              url: this.state.ruta_ensayos_masw,
              format: new GeoJSON()
            });
            this.vectorMasw = new VectorLayer({
              name: 'Manual',
              source: this.sourceMasw,
              style: this.styleMasw,
              visible: true
            });
    
            //MAM
            this.fillMam = new Fill({color: 'rgba(87,35,100,.9)'});
            this.strokeMam = new Stroke({color: 'rgba(0,0,0,.9)',width: 1});
            this.styleMam = new Style({
              image: new Circle({
                fill: this.fillMam,
                stroke: this.strokeMam,
                radius: 8
                }),
              //image: new Icon({
              //  anchor: [0.5, 46],
              //  anchorXUnits: 'fraction',
              //  anchorYUnits: 'pixels',
              //  src: logo_mam,
              //  scale: 0.02
              //}),
                fill:this.fillMam,
                stroke: this.strokeMam,
                text: new Text({
                //text: "Punto !!",
                font: 'bold 11px sans-serif',
                color: 'red',
              }),
            });  
            this.sourceMam = new VectorSource({
              url: this.state.ruta_ensayos_mam,
              format: new GeoJSON()
            });
            this.color_point = (tipo) => {
              if (tipo == "MAM") {
                return 'rgba(87,35,100,.9)'
              }
              if (tipo == "Espectro de Respuesta") {
                return 'rgba(16,184,16,.9)'
              }
              if (tipo == "CALICATA") {
                return 'rgba(239,184,16,.9)'
              }
              if (tipo == "SPT") {
                return 'rgba(220,220,220,.9)'
              } 
              
            };
            //esta es una forma de forma recursiva de los estilos, osea por alguna caracteristica en particular va a coger un tipo de color distinto, en ese caso, por la variable tipo_ensayo, x eso cuando el geojson ddel mam si es spt pinta un color, si es calicata pinta otro color, segu n lo que declares en  esta funcion color_point
            this.styleCacheMam = {};
            this.vectorMam = new VectorLayer({
              name: 'Mam',
              source: this.sourceMam,
              style: (feature) => {
                this.tipo_ensayo = feature.get('tipo_ensayo');
                this.radius = 8;
                this.style = this.styleCacheMam[this.radius];
                if (!this.style) {
                  this.style = new Style({
                    image: new Circle({
                      radius: this.radius,
                      fill: new Fill({color: this.color_point(this.tipo_ensayo)}),
                      stroke: new Stroke({color: 'rgba(0,0,0,.9)',width: 1})
                    })
                  });
                  //this.styleCacheMam[this.radius] = this.style;
                }
                return this.style;
              },
              visible: true
            });
    
            //Microtremor  // este es similar al masw
            this.fillMicrotremor = new Fill({color: 'rgba(255,0,0,.9)'});
            this.strokeMicrotremor = new Stroke({color: 'rgba(0,0,0,.9)',width: 1});
            this.styleMicrotremor = new Style({
              image: new Circle({
                fill: this.fillMicrotremor,
                stroke: this.strokeMicrotremor,
                radius: 7
                }),
              //image: new Icon({
              //  anchor: [0.5, 46],
              //  anchorXUnits: 'fraction',
              //  anchorYUnits: 'pixels',
              //  src: logo_microtremor,
              //  scale: 0.04
              //}),
                fill:this.fillMicrotremor,
                stroke: this.strokeMicrotremor,
                text: new Text({
                //text: "Punto !!",
                font: 'bold 11px sans-serif',
                color: 'red',
              }),
            });  
            this.sourceMicrotremor = new VectorSource({
              url: this.state.ruta_ensayos_microtremor,
              format: new GeoJSON()
            });
            this.vectorMicrotremor = new VectorLayer({
              name: 'Manual',
              source: this.sourceMicrotremor,
              style: this.styleMicrotremor,
              visible: true
            });
    
            //Perforacion
            this.fillPerforacion = new Fill({color: 'rgba(0,170,228,0.9)'});
            this.strokePerforacion = new Stroke({color: 'rgba(0,0,0,1)',width: 1});
            this.stylePerforacion = new Style({
              image: new Circle({
                fill: this.fillPerforacion,
                stroke: this.strokePerforacion,
                radius: 8
                }),
              //image: new Icon({
              //  anchor: [0.5, 46],
              //  anchorXUnits: 'fraction',
              //  anchorYUnits: 'pixels',
              //  src: logo_perforacion,
              //  scale: 0.02
              //}),
                fill:this.fillPerforacion,
                stroke: this.strokePerforacion,
                text: new Text({
                //text: "Punto !!",
                font: 'bold 11px sans-serif',
                color: 'red',
              }),
            });  
            this.sourcePerforacion = new VectorSource({
              url: this.state.ruta_ensayos_perforacion,
              format: new GeoJSON()
            });
            this.vectorPerforacion = new VectorLayer({
              name: 'Manual',
              source: this.sourcePerforacion,
              style: this.stylePerforacion,
              visible: true
            });

            
            
            this.fillCalicata = new Fill({color: 'rgba(239, 184, 16, .99)'});
            this.strokeCalicata = new Stroke({color: 'rgba(0,0,0,1)',width: 1});
            this.styleCalicata = new Style({
              image: new Circle({
                fill: this.fillCalicata,
                stroke: this.strokeCalicata,
                radius: 8
                }),
              //image: new Icon({
              //  anchor: [0.5, 46],
              //  anchorXUnits: 'fraction',
              //  anchorYUnits: 'pixels',
              //  src: logo_perforacion,
              //  scale: 0.02
              //}),
                fill:this.fillCalicata,
                stroke: this.strokeCalicata,
                text: new Text({
                //text: "Punto !!",
                font: 'bold 11px sans-serif',
                color: 'red',
              }),
            }); 
            this.sourceCalicata = new VectorSource({
              url: this.state.ruta_ensayos_calicata,
              format: new GeoJSON()
            });
            this.styleCacheCalicata = {};
            this.vectorCalicata = new VectorLayer({
              name: 'Manual',
              source: this.sourceCalicata,
              style: this.styleCalicata,
              visible: true
            });

    
            this.sourceSpt = new VectorSource({
              url: this.state.ruta_ensayos_spt,
              format: new GeoJSON()
            });
            this.styleCacheSpt = {};
            this.vectorSpt = new VectorLayer({
              name: 'Calicata',
              source: this.sourceSpt,
              style: (feature) => {
                this.tipo_ensayo = feature.get('tipo_ensayo');
                this.radius = 8;
                this.style = this.styleCacheSpt[this.radius];
                if (!this.style) {
                  this.style = new Style({
                    image: new Circle({
                      radius: this.radius,
                      fill: new Fill({color: this.color_point(this.tipo_ensayo)}),
                      stroke: new Stroke({color: 'rgba(0,0,0,.9)',width: 1})
                    })
                  });
                  //this.styleCacheMam[this.radius] = this.style;
                }
                return this.style;
              },
              visible: true
            }); 

            this.sourceEspectroRespuesta = new VectorSource({
              url: this.state.ruta_ensayos_espectrorespuesta,
              format: new GeoJSON()
            });
            this.styleCacheEspectroRespuesta = {};
            this.vectorEspectroRespuesta = new VectorLayer({
              name: 'Espectro de Respuesta',
              source: this.sourceEspectroRespuesta,
              style: (feature) => {
                this.tipo_ensayo = feature.get('tipo_ensayo');
                this.radius = 8;
                this.style = this.styleCacheEspectroRespuesta[this.radius];
                if (!this.style) {
                  this.style = new Style({
                    image: new Circle({
                      radius: this.radius,
                      fill: new Fill({color: this.color_point(this.tipo_ensayo)}),
                      stroke: new Stroke({color: 'rgba(0,0,0,.9)',width: 1})
                    })
                  });
                  //this.styleCacheMam[this.radius] = this.style;
                }
                return this.style;
              },
              visible: true
            });
    
            //Click  // este es para cuando haga click, su punto de referencia varie
            this.fillClick = new Fill({color: 'rgba(0,0,255,0.3)'});
            this.strokeClick = new Stroke({color: 'rgba(255,0,0,0.9)',width: 3});
            this.styleClick = new Style({
              image: new Circle({
                fill: this.fillClick,
                stroke: this.strokeClick,
                radius: 8
                }),
              stroke: this.strokePosition,
              fill: this.fillPosition,
              text: new Text({
                text: "Referencia !!",
                font: 'bold 11px sans-serif',
              })
            });
            this.sourceClick = new VectorSource({
              url: 'https://microzonificacion-data.cid-allpanchis.org/microzon/click/-8.145130624366516,-75.9557145755291',
              format: new GeoJSON()
            });
            this.vectorClick = new VectorLayer({
              name: 'Manual',
              source: this.sourceClick,
              style: this.styleClick,
              visible: false
            });
            //Esta es la vista del mapa, el centro y el zoom
            this.view = new View({
                center: fromLonLat([-75.741361, -14.076759]),
                zoom: 9,
                minZoom: 2,
                maxZoom: 28,
                //projection: new Projection({
                //    code: 'EPSG:4326',
                //    units: 'degrees'
                //})
            });
    
            this.extend = new ZoomToExtent({
                extent: [
                    813079.7791264898, 5929220.284081122,
                    848966.9639063801, 5936863.986909639
                  ],
                label: "E",
                tipLabel: "Extender para centrar mapa"
            });
            this.myControlLayers = new Control({
              element: document.getElementById('control_prueba'),
            });
            //Popup
            
            /* this.pe = document.getElementById('popup');
            this.popup = new Overlay({
              element: this.pe,
              id: 'popup',
              positioning: 'bottom-center',
              autoPan: true,
              autoPanAnimation: {
                duration: 250
              },
              stopEvent: false,
              offset: [0, -50]
            }); */
            //Mapa --- aqui es el mapa, donde vas a poner todas las capas que quieras que aparezca
            this.map =  new Map({
              view: this.view,
              controls: [ 
                new Zoom({className:"bg-dark zoomweb", zoomInTipLabel:"Acerca el Mapa",zoomOutTipLabel:"Aleja el Mapa"}), 
                //new Attribution({className:"bg-dark atribution", collapsible:true, collapseLabel: "Joseph Montero", tipLabel:"Atribuciones" }), 
                new ZoomSlider({duration:100}), 
                //new ZoomToExtent({className:"bg-dark",tipLabel: "Centrar mapa"}), 
                new FullScreen({tipLabel:"Pantalla completa",className:"fullscreenweb"}), 
                new ScaleLine({ minWidth:100}), 
                //new OverviewMap({className:"bg-dark", layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
                new Rotate({autoHide:true,className:"bg-dark rotateweb", tipLabel:"Rotar pantalla para centrar"})],
              layers: [this.OSM, this.WaterColor, this.Etiquetas, this.bingmap, this.bingmapRoad, this.ByN, this.terreno, this.vectorLayer, this.vectorLayer1, this.vectorLayer2, this.vectorZona1, this.vectorZona1a, this.vectorZona1b, this.vectorZona2, this.vectorZona2a, this.vectorZona2b, this.vectorZona3, this.vectorZona3a, this.vectorZona3b, this.vectorZona4,this.vectorZona4a,this.vectorZona4b, this.vectorZona5, this.vectorZona5a, this.vectorZona5b, this.vectorMicrotremor, this.vectorPerforacion, this.vectorMam, this.vectorMasw, this.vectorCalicata, this.vectorSpt,this.vectorEspectroRespuesta, this.vectorClick], // 
              // target: this.refs.mapContainer
            });
            //this.map.addControl(this.myControlLayers);
    
            //GeoPosicionamiento
            this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
            this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
            this.geoposition = new Geolocation({
              trackingOptions: { enableHighAccuracy: true },
              projection: this.view.getProjection()
            });
            // this.geoposition.setTracking('true');
            // display popup on click
            this.acercamiento = (evt) => {
              this.feature = this.map.getFeaturesAtPixel(evt.pixel);
              /* console.log(this.feature);
              this.point_on_click = this.map.getCoordinateFromPixel(evt.pixel);
              this.point_on_click_lat_lon = transform(this.point_on_click,'EPSG:900913', 'EPSG:4326');
              this.setState({lat_filter:this.point_on_click_lat_lon[1], lon_filter:this.point_on_click_lat_lon[0],caracteristicas_puntos:'sin_contenido_mapa_web_web',});
              if (this.feature.length>=1){
                this.coordenadas = transform(evt.coordinate,'EPSG:900913', 'EPSG:4326');
                this.createcomponente(this.feature[0].get('tipo_ensayo'),this.feature[0].get('name'),this.feature[0].get('departamento'),this.feature[0].get('provincia'),this.feature[0].get('distrito'),this.feature[0].get('zona'),this.feature[0].get('coordUTM1'),this.feature[0].get('coordUTM2'),this.feature[0].get('fuente'),this.feature[0].get('periodo'),this.feature[0].get('velcorte'),this.feature[0].get('logo'),this.feature[0].get('img1'),this.feature[0].get('img2'),this.feature[0].get('img3'),this.feature[0].get('documentacion'),this.feature[0].get('latitud'),this.feature[0].get('longitud'));
                this.setState({lat_filter:this.feature[0].get('latitud'),lon_filter:this.feature[0].get('longitud'),});
              } */
              if (this.feature.length>=1){
                //this.coordenadas = transform(evt.coordinate,'EPSG:900913', 'EPSG:4326');
                this.caract=this.feature[0];
                this.createcomponente(this.caract.get('tipo_ensayo'),this.caract.get('name'),this.caract.get('departamento'),this.caract.get('provincia'),this.caract.get('distrito'),this.caract.get('zona'),this.caract.get('coordUTM1'),this.caract.get('coordUTM2'),this.caract.get('fuente'),this.caract.get('periodo'),this.caract.get('velcor'),this.caract.get('logo'),this.caract.get('img1'),this.caract.get('img2'),this.caract.get('img3'),this.caract.get('documentacion'),this.caract.get('latitud'),this.caract.get('longitud'));
                this.setState({lat_filter:this.caract.get('latitud'),lon_filter:this.caract.get('longitud'),name_ini:this.caract.get('name_ini'),proyecto:this.caract.get('proyecto')});
              }
              else{
                this.point_on_click = this.map.getCoordinateFromPixel(evt.pixel);
                this.point_on_click_lat_lon = transform(this.point_on_click,'EPSG:900913', 'EPSG:4326');
                this.setState({lat_filter:this.point_on_click_lat_lon[1], lon_filter:this.point_on_click_lat_lon[0],caracteristicas_puntos:'sin_contenido_mapa_web_web',});
                this.sourceClick.setUrl('https://microzonificacion-data.cid-allpanchis.org/microzon/click/'+this.point_on_click_lat_lon[1]+','+this.point_on_click_lat_lon[0]);
                this.sourceClick.refresh();
              }
    
            };
            this.caracteristicas = (evt) => {
              this.feature = this.map.getFeaturesAtPixel(evt.pixel);
              if (this.feature.length==1){            
                this.popup.setPosition(evt.coordinate);
                console.log(evt.coordinate);
                this.popup.getElement().innerHTML='<div class="table-responsive table-sm table-hover table-striped text-nowrap tabla_caracteristicas"><h4>Características</h4><table class="table"><thead className="black white-text"><tr><th scope="col">Id</th><th scope="col">Nombre</th><th scope="col">Símbolo</th></tr></thead><tbody><tr><th scope="row">1</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">2</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">3</th><td>Cell</td><td>Cell</td></tr></tbody></table></div>';
                
                this.map.addOverlay(this.popup);
                
              }
              else{
                this.map.removeOverlay(this.popup);
              }
            };
            this.map.on('singleclick', this.acercamiento);
            //this.map.on('dblclick', this.caracteristicas);
            //this.map.on()
            // cambiar el cursor cuando pasas a un evento
            this.cambiacursor = (e) => {
              //if(e.dragging){
              //  e.popover('destroy');
              //};
              this.pixel = this.map.getEventPixel(e.originalEvent);
              this.hit = this.map.hasFeatureAtPixel(this.pixel);
              this.map.getTarget().style.cursor = this.hit ?'pointer':'';
            };
            this.map.on('pointermove',this.cambiacursor);
        }
        createcomponente=(tipo_en,nomb,depa,prov,ensay,zona,_UTM1,_UTM2,fuent,periodo,velco,logo,img1,img2,img3,docum,latitud,longitud,name_ini,proyecto)=>{
          this.setState({
            caracteristicas_puntos:'contenido_mapa_web',
            tipo_ensayo:tipo_en,
            nombre_ensayo:nomb,
            departamento_ensayo:depa,
            provincia_ensayo:prov,
            distrito_ensayo:ensay,
            zona_ensayo: zona,
            coordUTM1_ensayo:_UTM1,
            coordUTM2_ensayo:_UTM2,
            fuente_ensayo:fuent,
            periodo_ensayo: periodo,
            velcorte_ensayo:velco,
            logo_ensayo:logo,
            img1_ensayo: this.state.base_images+img1,
            img2_ensayo: this.state.base_images+img2,
            img3_ensayo: this.state.base_images+img3,
            documentacion_ensayo:docum,
            longitud_zoom:longitud,
            latitud_zoom:latitud,
            name_ini:name_ini,
            proyecto:proyecto
          });
          if (tipo_en=="MASW") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_masw'})};
          if (tipo_en=="MAM") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_mam'})};
          if (tipo_en=="Microtremor") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_microtremor'})};
          if (tipo_en=="Perforación") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_perforacion'})};
          if (tipo_en=="Espectro de Respuesta") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_espectrorespuesta'})};
          if (tipo_en=="CALICATA") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_CALICATA'})};
          if (tipo_en=="SPT") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_SPT'})};
        }
    
        hacer_zoom = () =>{
          this.coordenadasS = [this.state.longitud_zoom, this.state.latitud_zoom];
          this.map.setView(
            new View({
              center: fromLonLat(this.coordenadasS),
              zoom: 18
            })
          );
        }
        centrar_mapa = () =>{
          this.map.setView(
            new View({
              center: fromLonLat([-76.048, -9.4913]),
              zoom: 5.9,
            })
          );
        }
        cerrar_caracteristicas = () =>{
          this.setState({
            caracteristicas_puntos:'sin_contenido_mapa_web_web'
          })
        }
        funcion_seccion_marcadores = () =>{
          if(this.state.seccion_marcadores=='seccion_marcadores_oculto'){
            this.setState({seccion_marcadores: 'seccion_marcadores', seccion_lista: 'seccion_lista_oculto', seccion_mapas:'seccion_mapas_oculto'})
          }
          else{
            this.setState({seccion_marcadores: 'seccion_marcadores_oculto'})
          }
        }
        funcion_seccion_lista = () =>{
          if(this.state.seccion_lista=='seccion_lista_oculto'){
            this.setState({seccion_lista: 'seccion_lista', seccion_marcadores: 'seccion_marcadores_oculto', seccion_mapas: 'seccion_mapas_oculto'})
          }
          else{
            this.setState({seccion_lista: 'seccion_lista_oculto'})
          }
        }
        funcion_seccion_mapas = () =>{
          if(this.state.seccion_mapas=='seccion_mapas_oculto'){
            this.setState({seccion_mapas: 'seccion_mapas', seccion_marcadores: 'seccion_marcadores_oculto', seccion_lista: 'seccion_lista_oculto'})
          }
          else{
            this.setState({seccion_mapas: 'seccion_mapas_oculto'})
          }
        }
        cerrar_filtros = () =>{
          this.setState({
            panel_filtros:'sin_panel_filtro'
          })
        }
        cuando_cambia = (event) => {
          this.setState({
            tipo_filtro: event.target.value
          });
          if(event.target.value=='none'){
            this.setState({
              state_query: '0',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='departamento'){
            this.setState({
              state_query: '1',
              tipo_departamento:'Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='provincia'){
            this.setState({
              state_query: '2',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='distrito'){
            this.setState({
              state_query: '3',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='vs'){
            this.setState({
              state_query:'4',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='periodo'){
            this.setState({
              state_query:'5',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'Periodo',
              tipo_radio: 'sin_Radio',
            });
            this.vectorClick.setVisible(false);
          }
          if(event.target.value=='area'){
            this.setState({
              state_query:'6',
              tipo_departamento:'sin_Departamento',
              tipo_provincia: 'sin_Provincia',
              tipo_distrito: 'sin_Distrito',
              tipo_vs: 'sin_Vs',
              tipo_periodo: 'sin_Periodo',
              tipo_radio: 'Radio',
            });
            this.vectorClick.setVisible(true);
          }
        }
        cuando_cambia_departamento = (event) =>{this.setState({departamento: event.target.value})}
        cuando_cambia_provincia = (event) =>{this.setState({provincia: event.target.value})}
        cuando_cambia_distrito = (event) =>{this.setState({distrito: event.target.value})}
        cuando_cambia_vs = (event) => {this.setState({vs:event.target.value})}
        cuando_cambia_periodo = (event) => {this.setState({periodo:event.target.value})}
        cuando_cambia_radio = (event) => {this.setState({radio:event.target.value})}
        cuando_cambia_ciudad = (event) => {this.setState({ciudad: event.target.value})}
        cuando_cambia_ano = (event) => {this.setState({ano: event.target.value})}
        cuando_cambia_fuente = (event) =>{this.setState({fuente: event.target.value})}
        cuando_filtra = (event) => {
          console.log(event);
          this.centrar_mapa();
          this.setState({caracteristicas_puntos:'sin_contenido_mapa_web_web'});
          //alert('Este es tipo de filtro seleccionado: '+ this.state.tipo_filtro);
          if(this.state.state_query=='1'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.departamento;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.departamento;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.departamento;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.departamento;
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
          if(this.state.state_query=='2'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.provincia;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.provincia;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.provincia;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.provincia;
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
          if(this.state.state_query=='3'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.distrito;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.distrito;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.distrito;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.distrito;        
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
          if(this.state.state_query=='4'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.vs;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.vs;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.vs;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.vs;
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
          if(this.state.state_query=='5'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.periodo;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.periodo;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.periodo;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.periodo;
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
          if(this.state.state_query=='6'){
            this.path_temporal_masw='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
            this.path_temporal_mam='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
            this.path_temporal_microtremor='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
            this.path_temporal_perforacion='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
            this.sourceMasw.setUrl(this.path_temporal_masw);
            this.sourceMasw.refresh();
            this.sourceMam.setUrl(this.path_temporal_mam);
            this.sourceMam.refresh();
            this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
            this.sourceMicrotremor.refresh();
            this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
            this.sourcePerforacion.refresh();
            this.setState({panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0,});
            this.Obtener_Datos_Filter_Masw(this.path_temporal_masw);
            this.cambia_status_masw(this.path_temporal_masw);
            this.Obtener_Datos_Filter_Mam(this.path_temporal_mam);
            this.cambia_status_mam(this.path_temporal_mam);
            this.Obtener_Datos_Filter_Microtremor(this.path_temporal_microtremor);
            this.cambia_status_microtremor(this.path_temporal_microtremor);
            this.Obtener_Datos_Filter_Perforacion(this.path_temporal_perforacion);
            this.cambia_status_perforacion(this.path_temporal_perforacion);
          }
                    
          event.preventDefault();
          }
        onSetSidebarOpen(open) {
          this.setState({ sidebarOpen: open });
        }
    
        //Seccion Tabla
        cantidad_ensayos_totales(){
          if (this.state.loading_masw){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_totales}</span>
          )
        };
        cambiaTabla1(valor){
          if(valor == 1){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_uno:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_activo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo'
            })
          }
          if(valor == 2){
            this.setState({ 
              boton_control_uno: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_dos:'botones_tablas_active',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_mam: 'contenido_datos_activo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo'
            })
          }
          if(valor == 3){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_tres:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_activo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo'
            })
          }
          if(valor == 4){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_cuatro:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_activo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo'
            })
          }
          if(valor == 5){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro:'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_cinco: 'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_activo',
              contenido_datos_calicata: 'contenido_datos_activo',
              contenido_datos_spt: 'contenido_datos_inactivo'
            })
          }
          if(valor == 6){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro:'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_activo'
            })
          }
        };
        cambiaTabla(e){
          if(e.target.value == '1'){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_uno:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_activo'
            })
          }
          if(e.target.value == '2'){
            this.setState({ 
              boton_control_uno: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_dos:'botones_tablas_active',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo',
              contenido_datos_mam: 'contenido_datos_activo'
            })
          }
          if(e.target.value == '3'){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_tres:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_activo'
            })
          }
          if(e.target.value == '4'){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_cuatro:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_activo'
            })
          }
          if(e.target.value == '5'){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_seis: 'botones_tablas',
              boton_control_cinco:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_espectrorespuesta: 'contenido_datos_activo',
              contenido_datos_spt: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_activo',
            })
          }
          if(e.target.value == '6'){
            this.setState({ 
              boton_control_dos: 'botones_tablas',
              boton_control_tres: 'botones_tablas',
              boton_control_uno: 'botones_tablas',
              boton_control_cuatro: 'botones_tablas',
              boton_control_cinco: 'botones_tablas',
              boton_control_seis:'botones_tablas_active',
              contenido_datos_mam: 'contenido_datos_inactivo',
              contenido_datos_microtremor: 'contenido_datos_inactivo',
              contenido_datos_masw: 'contenido_datos_inactivo',
              contenido_datos_perforacion: 'contenido_datos_inactivo',
              contenido_datos_calicata: 'contenido_datos_inactivo',
              contenido_datos_spt: 'contenido_datos_activo',
            })
          }
        };
        //Tabla MASW
        cambia_status_masw=(ruta)=>{
          this.setState({
            url_axios_masw:ruta,
            loading_masw:true
          });
        }
        contenido_masw(){
          if (this.state.loading_masw){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_masw.map(
              ensayo =><li className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className="simbolo_leyenda_t_masw">Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>MASW</small><br/>
                  <small><strong>Coord: </strong>{Number(ensayo.properties.coordUTM1).toFixed(2)} , {Number(ensayo.properties.coordUTM2).toFixed(2)}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver" /></small><br/>
                </div>
              </li>
              
            )
          )
        };
        cantidad_ensayos_masw(){
          if (this.state.loading_masw){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_masw}</span>
          )
        };
        async Obtener_Datos_Filter_Masw(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_masw: response.data.features, cantidad_ensayos_masw: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_masw:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Masw(){
          await axios.get(this.state.url_axios_masw)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_masw: response.data.features, cantidad_ensayos_masw: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_masw:false})
          })
          .catch(e => {console.log(e)})
        }
        
        //Tabla MAM
        cambia_status_mam=(ruta)=>{
          this.setState({
            url_axios_mam:ruta,
            loading_mam:true
          });
        }
        contenido_mam(){
          if (this.state.loading_mam){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_mam.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className={"simbolo_leyenda_t_".concat(ensayo.properties.tipo_ensayo)}>Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>{ensayo.properties.tipo_ensayo}</small><br/>
                  <small><strong>Coord: </strong>{Number(ensayo.properties.coordUTM1).toFixed(2)} , {Number(ensayo.properties.coordUTM2).toFixed(2)}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver"/></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_mam(){
          if (this.state.loading_mam){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_mam}</span>
          )
        };
        async Obtener_Datos_Filter_Mam(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_mam: response.data.features, cantidad_ensayos_mam: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_mam:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Mam(){
          await axios.get(this.state.url_axios_mam)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_mam: response.data.features, cantidad_ensayos_mam: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_mam:false})
          })
          .catch(e => {console.log(e)})
        }
    
        //Tabla Microtremor
        cambia_status_microtremor=(ruta)=>{
          this.setState({
            url_axios_microtremor:ruta,
            loading_microtremor:true
          });
        }
        contenido_microtremor(){
          if (this.state.loading_microtremor){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_microtremor.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className="simbolo_leyenda_t_microtremor">Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>MICROTREMOR </small><br/>
                  <small><strong>Coord: </strong>{Number(ensayo.properties.coordUTM1).toFixed(2)} , {Number(ensayo.properties.coordUTM2).toFixed(2)}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver"/></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_microtremor(){
          if (this.state.loading_microtremor){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_microtremor}</span>
          )
        };
        async Obtener_Datos_Filter_Microtremor(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_microtremor: response.data.features, cantidad_ensayos_microtremor: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_microtremor:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Microtremor(){
          await axios.get(this.state.url_axios_microtremor)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_microtremor: response.data.features, cantidad_ensayos_microtremor: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_microtremor:false})
          })
          .catch(e => {console.log(e)})
        }
    
        //Tabla Perforacióm
        cambia_status_perforacion=(ruta)=>{
          this.setState({
            url_axios_perforacion:ruta,
            loading_perforacion:true
          });
        }
        contenido_perforacion(){
          if (this.state.loading_perforacion){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_perforacion.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className="simbolo_leyenda_t_perforacion">Me</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>PERFORACIÓN </small><br/>
                  <small><strong>Coord:: </strong>{Number(ensayo.properties.coordUTM1).toFixed(2)} , {Number(ensayo.properties.coordUTM2).toFixed(2)}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver" /></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_perforacion(){
          if (this.state.loading_perforacion){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_perforacion}</span>
          )
        };
        async Obtener_Datos_Filter_Perforacion(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_perforacion: response.data.features, cantidad_ensayos_perforacion: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_perforacion:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Perforacion(){
          await axios.get(this.state.url_axios_perforacion)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_perforacion: response.data.features, cantidad_ensayos_perforacion: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_perforacion:false})
          })
          .catch(e => {console.log(e)})
        }

        //Tabla Espectro Respuesta
        cambia_status_espectrorespuesta=(ruta)=>{
          this.setState({
            url_axios_espectrorespuesta:ruta,
            loading_espectrorespuesta:true
          });
        }
        contenido_espectrorespuesta(){
          if (this.state.loading_espectrorespuesta){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_espectrorespuesta.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className={"simbolo_leyenda_t_".concat(ensayo.properties.tipo_ensayo)}>Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>{ensayo.properties.tipo_ensayo} </small><br/>
                  <small><strong>Coord: </strong>{Number(ensayo.properties.coordUTM1).toFixed(2)} , {Number(ensayo.properties.coordUTM2).toFixed(2)}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver"/></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_espectrorespuesta(){
          if (this.state.loading_espectrorespuesta){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_espectrorespuesta}</span>
          )
        };
        async Obtener_Datos_Filter_EspectroRespuesta(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_espectrorespuesta: response.data.features, cantidad_ensayos_espectrorespuesta: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_espectrorespuesta:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_EspectroRespuesta(){
          await axios.get(this.state.url_axios_espectrorespuesta)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_espectrorespuesta: response.data.features, cantidad_ensayos_espectrorespuesta: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_espectrorespuesta:false})
          })
          .catch(e => {console.log(e)})
        }


        //Tabla Calicata
        cambia_status_calicata=(ruta)=>{
          this.setState({
            url_axios_caticata:ruta,
            loading_calicata:true
          });
        }
        contenido_calicata(){
          if (this.state.loading_calicata){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_calicata.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className={"simbolo_leyenda_t_".concat(ensayo.properties.tipo_ensayo)}>Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong>{ensayo.properties.tipo_ensayo} </small><br/>
                  <small><strong>Coord: </strong>{ensayo.properties.coordUTM1} , {ensayo.properties.coordUTM2}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver"/></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_calicata(){
          if (this.state.loading_calicata){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_calicata}</span>
          )
        };
        async Obtener_Datos_Filter_Calicata(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_calicata: response.data.features, cantidad_ensayos_calicata: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_calicata:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Calicata(){
          await axios.get(this.state.url_axios_calicata)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_calicata: response.data.features, cantidad_ensayos_calicata: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_calicata:false})
          })
          .catch(e => {console.log(e)})
        } 
    
        
        // Tabla SPT
        cambia_status_spt=(ruta)=>{
          this.setState({
            url_axios_spt:ruta,
            loading_spt:true
          });
        }
        contenido_spt(){
          if (this.state.loading_spt){
            return(<span>Loading..</span>)
          }
          return(
            this.state.ensayos_spt.map(
              ensayo =><div className="Item_Table"  key={ensayo.id} onClick={() => this.createcomponente(ensayo.properties.tipo_ensayo,ensayo.properties.name,ensayo.properties.departamento,ensayo.properties.provincia,ensayo.properties.distrito,ensayo.properties.zona,ensayo.properties.coordUTM1,ensayo.properties.coordUTM2,ensayo.properties.fuente,ensayo.properties.periodo,ensayo.properties.velcor,ensayo.properties.logo,ensayo.properties.img1,ensayo.properties.img2,ensayo.properties.img3,ensayo.properties.documentacion,ensayo.properties.latitud,ensayo.properties.longitud,ensayo.properties.name_ini,ensayo.properties.proyecto)}>
                <div className="Logo_Item_Table"><span className={"simbolo_leyenda_t_".concat(ensayo.properties.tipo_ensayo)}>Ms</span></div>
                <div className="Cont_Item_Table">
                  <small><strong>Tipo Ensayo: </strong> {ensayo.properties.tipo_ensayo}</small><br/>
                  <small><strong>Coord: </strong>{ensayo.properties.coordUTM1} , {ensayo.properties.coordUTM2}</small><br/>
                  <small><strong>Fuente: </strong>{ensayo.properties.fuente}</small><br/>
                  <small className="ultimo_item_table"><strong>Departamento: </strong>{ensayo.properties.departamento} <FaRegEye className="ver"/></small><br/>
                </div>
              </div>
              
            )
          )
        };
        cantidad_ensayos_spt(){
          if (this.state.loading_spt){
            return(<span>Loading..</span>)
          }
          return(
            <span>{this.state.cantidad_ensayos_spt}</span>
          )
        };
        async Obtener_Datos_Filter_Spt(data_ingresa){
          await axios.get(data_ingresa)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_spt: response.data.features, cantidad_ensayos_spt: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_spt:false})
          })
          .catch(e => {console.log(e)})
        }
        async Obtener_Datos_Spt(){
          await axios.get(this.state.url_axios_spt)
          .then(response =>{
            console.log(response);
            this.setState({ensayos_spt: response.data.features, cantidad_ensayos_spt: response.data.cantidad, cantidad_ensayos_totales:this.state.cantidad_ensayos_totales+response.data.cantidad, loading_spt:false})
          })
          .catch(e => {console.log(e)})
        } 
    
        //Sección Detalle
        cambiaDetalle(e){
          if(e.target.value == '1'){
            this.setState({
              control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton1: 'Control_Detalle_Ensayo_boton_active',
              Contenido_Documentacion_Ensayo: 'Contenido_Documentacion_Ensayo_Inactive',
              Contenido_Imagenes_Ensayo: 'Contenido_Imagenes_Ensayo_Inactive',
              Contenido_Detalle_Ensayo: 'Contenido_Detalle_Ensayo',
            })
          }
          if(e.target.value == '2'){
            this.setState({
              control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton1: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton2: 'Control_Detalle_Ensayo_boton_active',
              Contenido_Documentacion_Ensayo: 'Contenido_Documentacion_Ensayo_Inactive',
              Contenido_Detalle_Ensayo: 'Contenido_Detalle_Ensayo_Inactive',
              Contenido_Imagenes_Ensayo: 'Contenido_Imagenes_Ensayo',
            })
          }
          if(e.target.value == '3'){
            this.setState({
              control_detalle_boton1: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive',
              control_detalle_boton3: 'Control_Detalle_Ensayo_boton_active',
              Contenido_Imagenes_Ensayo: 'Contenido_Imagenes_Ensayo_Inactive',
              Contenido_Detalle_Ensayo: 'Contenido_Detalle_Ensayo_Inactive',
              Contenido_Documentacion_Ensayo: 'Contenido_Documentacion_Ensayo',
            })
          }
        }
    
        contenido_filtros(){
          if (this.state.loading_Filtros){
            return(<span>...</span>)
          }
          return(
            this.state.filtros_distrito.map(
            dato =><option value={dato.distrito.nombre_distrito}>{dato.distrito.nombre_distrito} {dato.distrito.referencia}</option>
            )
          )
        }
        contenido_filtros_provincias(){
          if (this.state.loading_Filtros_One){
            return(<span>...</span>)
          }
          return(
            this.state.filtros_provincias.map(
            dato =><option value={dato.provincia.nombre_provincia}>{dato.provincia.nombre_provincia} {dato.provincia.referencia}</option>
            )
          )
        }
        async Obtener_Datos_filtro(){
          await axios.get(this.state.url_axios_Filtros)
          .then(response =>{
            console.log(response.data.distritos);
            console.log(response.data.provincias);
            this.setState({filtros_distrito: response.data.distritos, filtros_provincias: response.data.provincias, loading_Filtros:false, loading_Filtros_One:false})
          })
          .catch(e => {console.log(e)})
        }
    
        componentDidMount() {
            this.map.setTarget(this.refs.mapContainerWeb);
            //popup
            this.popup = new Overlay({
              element: ReactDOM.findDOMNode(this).querySelector('#popup'),
            });
            this.Obtener_Datos_Masw();
            this.Obtener_Datos_Filter_Masw();
            this.Obtener_Datos_Mam();
            this.Obtener_Datos_Filter_Mam();
            this.Obtener_Datos_Microtremor();
            this.Obtener_Datos_Filter_Microtremor();
            this.Obtener_Datos_Perforacion();
            this.Obtener_Datos_Filter_Perforacion();
            this.Obtener_Datos_EspectroRespuesta();
            this.Obtener_Datos_Filter_EspectroRespuesta();
            this.Obtener_Datos_Calicata();
            this.Obtener_Datos_Filter_Calicata();
            this.Obtener_Datos_Spt();
            this.Obtener_Datos_Filter_Spt();
            this.Obtener_Datos_filtro();
        }
        //Buscador de Direcciones
        cambia_direccion(event) {
          this.setState({value: event.target.value});
          console.log(this.state.value);
        }
      
        busca_direccion(event) {
          this.convert_direccion(this.state.value);
          event.preventDefault();
        }
        //Cambia Filtro de latitud
        cambia_lat_filter(event) {
          this.setState({lat_filter: event.target.value});
        }
        //Cambia Filtro de longitud
        cambia_lon_filter(event) {
          this.setState({lon_filter: event.target.value});
        }
    
        convert_direccion(direccion){
          // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
          Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
          // set response language. Defaults to english.
          Geocode.setLanguage("latam");
          // set response region. Its optional.
          // A Geocoding request with region=es (Spain) will return the Spanish city.
          Geocode.setRegion("pe");
          // Enable or disable logs. Its optional.
          Geocode.enableDebug();
          // Get latidude & longitude from address.
          Geocode.fromAddress(direccion).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location;
              console.log(lat, lng);
              this.center_marker(lat,lng,17);
              this.setState({
                lat_filter: lat,
                lon_filter: lng,
              });
            },
            error => {
              console.error(error);
            }
          );
        }
        //Cambia Mapas
        toggleShowBM=()=>{
          this.bingmap.setVisible(true);
          this.bingmapRoad.setVisible(false);
          this.OSM.setVisible(false);
          this.WaterColor.setVisible(false);
          this.ByN.setVisible(false);
          this.terreno.setVisible(false);
        };
        toggleShowBMR=()=>{
          this.bingmap.setVisible(false);
          this.bingmapRoad.setVisible(true);
          this.OSM.setVisible(false);
          this.WaterColor.setVisible(false);
          this.ByN.setVisible(false);
          this.terreno.setVisible(false);
        };
        toggleShowWC=()=>{
          this.bingmap.setVisible(false);
          this.bingmapRoad.setVisible(false);
          this.OSM.setVisible(false);
          this.WaterColor.setVisible(true);
          this.ByN.setVisible(false);
          this.terreno.setVisible(false);
        };
        toggleShowOSM=()=>{
          this.bingmap.setVisible(false);
          this.bingmapRoad.setVisible(false);
          this.OSM.setVisible(true);
          this.WaterColor.setVisible(false);
          this.ByN.setVisible(false);
          this.terreno.setVisible(false);
        };
        toggleShowByN=()=>{
          this.bingmap.setVisible(false);
          this.bingmapRoad.setVisible(false);
          this.OSM.setVisible(false);
          this.WaterColor.setVisible(false);
          this.ByN.setVisible(true);
          this.terreno.setVisible(false);
        }
        toggleShowTerreno=()=>{
          this.bingmap.setVisible(false);
          this.bingmapRoad.setVisible(false);
          this.OSM.setVisible(false);
          this.WaterColor.setVisible(false);
          this.ByN.setVisible(false);
          this.terreno.setVisible(true);
        }
        
        filtrar_punto = () => {
          if(this.state.panel_filtros == 'sin_panel_filtro'){
            this.setState({
              panel_filtros:'panel_filtro'
            });
            console.log(this.state.panel_filtros)
          }
          else{
            this.setState({
              panel_filtros:'sin_panel_filtro'
            });
            console.log(this.state.panel_filtros)
          }
        }
        reset_filtros = () =>{
          this.setState({text_ensayos_web: 'text_ensayos_web',text_ensayos_web_ma: 'text_ensayos_web',text_ensayos_web_mi: 'text_ensayos_web',text_ensayos_web_pe: 'text_ensayos_web',estado_capa_nombre_masw:'Activo',estado_capa_masw:'Activo_masw',estado_capa_nombre_mam:'Activo',estado_capa_mam:'Activo_mam',estado_capa_nombre_microtremor:'Activo',estado_capa_microtremor:'Activo_microtremor',estado_capa_nombre_perforacion:'Activo',estado_capa_perforacion:'Activo_perforacion',panel_filtros:'sin_panel_filtro', cantidad_ensayos_totales:0, caracteristicas_puntos:'sin_contenido_mapa_web_web'});
          //this.path='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson';
          this.path='https://microzonificacion-data.cid-allpanchis.org/microzon/masw.geojson'
          this.path1='https://microzonificacion-data.cid-allpanchis.org/microzon/mam.geojson';
          this.path2='https://microzonificacion-data.cid-allpanchis.org/microzon/microtremor.geojson';
          this.path3='https://microzonificacion-data.cid-allpanchis.org/microzon/perforacion.geojson';
          this.sourceMasw.setUrl(this.path);
          this.sourceMasw.refresh();
          this.vectorMasw.setVisible(true);
          this.sourceMam.setUrl(this.path1);
          this.sourceMam.refresh();
          this.vectorMam.setVisible(true);
          this.sourceMicrotremor.setUrl(this.path2);
          this.sourceMicrotremor.refresh();
          this.vectorMicrotremor.setVisible(true);
          this.sourcePerforacion.setUrl(this.path3);
          this.sourcePerforacion.refresh();
          this.vectorPerforacion.setVisible(true);
          this.Obtener_Datos_Filter_Masw(this.path);
          this.cambia_status_masw(this.path);
          this.Obtener_Datos_Filter_Mam(this.path1);
          this.cambia_status_mam(this.path1);
          this.Obtener_Datos_Filter_Microtremor(this.path2);
          this.cambia_status_microtremor(this.path2);
          this.Obtener_Datos_Filter_Perforacion(this.path3);
          this.cambia_status_perforacion(this.path3);
          this.centrar_mapa();
        }
        Check=()=>{
          if(this.vectorMasw.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorMasw.setVisible(false);
            this.setState({estado_capa_nombre_masw:'Inactivo',estado_capa_masw:'Inactivo', text_ensayos_web: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorMasw.setVisible(true);
            this.setState({estado_capa_nombre_masw:'Activo',estado_capa_masw:'Activo_masw', text_ensayos_web: 'text_ensayos_web'})
          }
        };
        Check1=()=>{
          if(this.vectorMam.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorMam.setVisible(false);
            this.setState({estado_capa_nombre_mam:'Inactivo',estado_capa_mam:'Inactivo', text_ensayos_web_ma: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorMam.setVisible(true);
            this.setState({estado_capa_nombre_mam:'Activo',estado_capa_mam:'Activo_mam', text_ensayos_web_ma: 'text_ensayos_web'})
          }
        };
        Check2=()=>{
          if(this.vectorMicrotremor.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorMicrotremor.setVisible(false);
            this.setState({estado_capa_nombre_microtremor:'Inactivo',estado_capa_microtremor:'Inactivo', text_ensayos_web_mi: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorMicrotremor.setVisible(true);
            this.setState({estado_capa_nombre_microtremor:'Activo',estado_capa_microtremor:'Activo_microtremor', text_ensayos_web_mi: 'text_ensayos_web'})
          }
        };
        Check3=()=>{
          if(this.vectorPerforacion.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorPerforacion.setVisible(false);
            this.setState({estado_capa_nombre_perforacion:'Inactivo',estado_capa_perforacion:'Inactivo', text_ensayos_web_pe: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorPerforacion.setVisible(true);
            this.setState({estado_capa_nombre_perforacion:'Activo',estado_capa_perforacion:'Activo_perforacion', text_ensayos_web_pe: 'text_ensayos_web'})
          }
        };
        Check12=()=>{
          if(this.vectorEspectroRespuesta.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorEspectroRespuesta.setVisible(false);
            this.setState({estado_capa_nombre_espectro_respuesta:'Inactivo',estado_capa_espectro_respuesta:'Inactivo', text_ensayos_web_er: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorEspectroRespuesta.setVisible(true);
            this.setState({estado_capa_nombre_espectro_respuesta:'Activo',estado_capa_espectro_respuesta:'Activo_espectrorespuesta', text_ensayos_web_er: 'text_ensayos_web'})
          }
        };

        Check7=()=>{
          if(this.vectorCalicata.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorCalicata.setVisible(false);
            this.setState({estado_capa_nombre_calicata:'Inactivo',estado_capa_calicata:'Inactivo', text_ensayos_web_ca: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorCalicata.setVisible(true);
            this.setState({estado_capa_nombre_calicata:'Activo',estado_capa_calicata:'Activo_calicata', text_ensayos_web_ca: 'text_ensayos_web'})
          }
        };
        Check8=()=>{
          if(this.vectorSpt.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorSpt.setVisible(false);
            this.setState({estado_capa_nombre_spt:'Inactivo',estado_capa_spt:'Inactivo', text_ensayos_web_sp: 'text_ensayos_web_inactivo'})
          }
          else{
            this.vectorSpt.setVisible(true);
            this.setState({estado_capa_nombre_spt:'Activo',estado_capa_spt:'Activo_spt', text_ensayos_web_sp: 'text_ensayos_web'})
          }
        }; 
        Check4=()=>{
          if(this.vectorLayer.getVisible()){
            this.vectorLayer.setVisible(false);
            this.vectorLayer1.setVisible(false);
            this.vectorLayer2.setVisible(false)
          }
          else{
            this.vectorLayer.setVisible(true);
            this.vectorLayer1.setVisible(false);
            this.vectorLayer2.setVisible(false)
          }
        };
        Check5=()=>{
          if(this.vectorLayer1.getVisible()){
            this.vectorLayer.setVisible(false);
            this.vectorLayer1.setVisible(false);
            this.vectorLayer2.setVisible(false)
          }
          else{
            this.vectorLayer.setVisible(false);
            this.vectorLayer1.setVisible(true);
            this.vectorLayer2.setVisible(false)
          }
        };
        Check6=()=>{
          if(this.vectorLayer2.getVisible()){
            this.vectorLayer.setVisible(false);
            this.vectorLayer1.setVisible(false);
            this.vectorLayer2.setVisible(false)
          }
          else{
            this.vectorLayer.setVisible(false);
            this.vectorLayer1.setVisible(false);
            this.vectorLayer2.setVisible(true)
          }
        };
        
        Check_boton_geoposition=()=>{
          if(this.vectorLayerGP.getVisible()){
            //document.getElementById('check').setState('checked');
            this.vectorLayerGP.setVisible(false);
            this.setState({estado_boton_geoposition:'Inactivo'})
          }
          else{
            this.vectorLayerGP.setVisible(true);
            this.setState({estado_boton_geoposition:'Activo'})
          }
        };
        GeoPositionInMap=()=>{
          this.geoposition.setTracking('true');
          this.geoposition.setProjection();
          // this.geoposition.getPosition();
          this.geoposition.on('change',this.ObtenUbicacion);
          this.setState({boton_geoposition:'Geo_Activo',estado_boton_geoposition:'Activo'});
        };
        ObtenUbicacion=()=>{
          console.log("Aparezco cuando acepta el tracking");
          //console.log(this.geoposition.getPosition());
          this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
          this.geoposition.setTracking(false);
          console.log(this.coordinates[0]);
          console.log(this.coordinates[1]);
          this.setState({lat_filter:this.coordinates[1], lon_filter:this.coordinates[0]});
          this.iconFeature = new Feature({
            geometry: new Point(fromLonLat([this.coordinates[0], this.coordinates[1]])),
            tipo_ensayo: 'Geo Posicionamiento',
            name: 'Geo Posicionamiento',
            coordUTM1: parseFloat(this.coordinates[0]).toFixed(4),
            coordUTM2: parseFloat(this.coordinates[1]).toFixed(4),
            fuente: <a href='httpss://www.linkedin.com/in/joseph-marlon-montero-inga-aa3aa7106/' target='_blank'>Fuente del Programador</a>,
          });
          
          this.iconStyle = new Style({
            image: new Icon({
              anchor: [0.5, 46],
              anchorXUnits: 'fraction',
              anchorYUnits: 'pixels',
              //rc: 'httpsss://openlayers.org/en/latest/examples/data/icon.png',
              //src: 'httpsss://executiveedge.ie/wp-content/plugins/google-maps/assets/images/icons/greenmarker256.png',
              src: 'httpss://cdn.pixabay.com/photo/2016/01/14/14/37/marker-1140068_960_720.png',
              scale: 0.05
            }),
            stroke: this.strokePosition,
            fill: this.fillPosition,
            text: new Text({
              text: "Tú estas aquí !!",
                      font: 'bold 11px sans-serif',
            })
          });
          
          this.iconFeature.setStyle(this.iconStyle);
          
          this.vectorSourceGP = new VectorSource({
            features: [this.iconFeature]
          });
          
          this.vectorLayerGP = new VectorLayer({
            source: this.vectorSourceGP
          });
          
          this.map.setView(
            new View({
              center: fromLonLat(this.coordinates),
              zoom: 15
            })
          );
          this.map.addLayer(this.vectorLayerGP);
        };
    
        center_marker(latitud, longitud, zoom_acercar) {
          this.map.setView(
            new View({
              center: fromLonLat([longitud,latitud]),
              zoom: zoom_acercar
            })
          );
        }
        return_periodo(){
          return(<tr><td><strong>Periodo (s): </strong></td><td> {this.state.periodo_ensayo}</td></tr>);
        }
        return_vs_mam(){
          return(<tr><td><strong>V<sub>s (prom)</sub> (m/s): </strong></td><td> {this.state.velcorte_ensayo}</td></tr>);
        }
        return_vs_masw(){
          return(<tr><td><strong>V<sub>s (30)</sub> (m/s): </strong></td><td> {this.state.velcorte_ensayo}</td></tr>);
        }
        dinamic_detail(){
          if (this.state.tipo_ensayo=="MAM") {
            return(          
              this.return_vs_mam()
            );
          }
          if (this.state.tipo_ensayo=="MASW") {
            return(          
              this.return_vs_masw()
            );
          }
          if (this.state.tipo_ensayo=="Microtremor") {
            return(          
              this.return_periodo()
            );
          }
        }
    
        render() {
            return (
              <div className="general_section">
                
                <div className="section_one">
                  <div className="Total_Ensayos">
                    <h6 className="Titulo_T_Ensayos">Total de Ensayos Recopilados</h6>
                    <p className="Content_T_Ensayos">{this.cantidad_ensayos_totales()}</p>
                  </div>
                  <div className="Tablas_Ensayos">
                    <div className="Parte_Tabla">
                      <div className={this.state.contenido_datos_masw}>
                        <h6 className="titulo_tabla_ensayos">Tabla de Ensayos MASW</h6>
                        {this.contenido_masw()}
                      </div>
                      <div className={this.state.contenido_datos_mam}>
                      <h6 className="titulo_tabla_ensayos">Tabla de OTROS Ensayos</h6>
                        {this.contenido_mam()}
                      </div>
                      <div className={this.state.contenido_datos_microtremor}>
                      <h6 className="titulo_tabla_ensayos">Tabla de Ensayos MICROTREMOR</h6>
                        {this.contenido_microtremor()}
                      </div>
                      <div className={this.state.contenido_datos_perforacion}>
                      <h6 className="titulo_tabla_ensayos">Tabla de Ensayos PERFORACIÓN</h6>
                        {this.contenido_perforacion()}
                      </div>
                      <div className={this.state.contenido_datos_calicata}>
                      <h6 className="titulo_tabla_ensayos">Tabla de Ensayos CALICATA</h6>
                        {this.contenido_calicata()}
                      </div>
                      <div className={this.state.contenido_datos_spt}>
                      <h6 className="titulo_tabla_ensayos">Tabla de Ensayos SPT</h6>
                        {this.contenido_spt()}
                      </div>
                    </div>
                    <div className="Parte_Controles">
                      <div className="control_tablas">
                        <small>Control</small><br/><small>Tablas</small>
                      </div>
                      <div className="Mostrar_Tablas">
                        <button value="1" onClick={this.cambiaTabla} className={this.state.boton_control_uno}><span className="simbolo_leyenda_t_masw" onClick={()=>this.cambiaTabla1(1)}>Ms</span></button>
                        <button value="2" onClick={this.cambiaTabla} className={this.state.boton_control_dos}><span className="simbolo_leyenda_t_mam" onClick={()=>this.cambiaTabla1(2)}>Ms</span></button>
                        <button value="3" onClick={this.cambiaTabla} className={this.state.boton_control_tres}><span className="simbolo_leyenda_t_microtremor" onClick={()=>this.cambiaTabla1(3)}>Ms</span></button>
                        <button value="4" onClick={this.cambiaTabla} className={this.state.boton_control_cuatro}><span className="simbolo_leyenda_t_perforacion" onClick={()=>this.cambiaTabla1(4)}>Ms</span></button>
                        <button value="5" onClick={this.cambiaTabla} className={this.state.boton_control_cinco}><span value="5" onClick={this.cambiaTabla} className="simbolo_leyenda_t_calicata" onClick={()=>this.cambiaTabla1(5)}>Ms</span></button>
                        <button value="6" onClick={this.cambiaTabla} className={this.state.boton_control_seis}><span value="6" onClick={this.cambiaTabla} className="simbolo_leyenda_t_spt" onClick={()=>this.cambiaTabla1(6)}>Ms</span></button> 
                      </div>
                    </div>
                  </div>
                  <div className="Actualizacion">
                    <span>Última Actualización</span><br/><span><small>2020 Mayo 29 </small></span>
                  </div>
                </div>
                <div className="section_two">
                  <div className="section_two_one">
                    <div className="mapaWeb" id="mapContainerWeb" ref="mapContainerWeb"></div>
                    {/*Buscador de Direcciones*/}
                    <form onSubmit={this.busca_direccion} className="form-busca-direccion" title="Escribe tu dirección para encontrarte">
                      <label className="label-busca-direccion" title="Escribe tu dirección para encontrarte">
                        <input  className="input-busca-direccion" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa tu direción.." title="Escribe tu dirección para encontrarte"/>
                        <FaSearchLocation className="icon-busca-direccion" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                      </label>
                    </form>
                    <div className="type_maps_web">
                      <button title="Marcadores de Posición" className="type_maps_web_button1" onClick={this.funcion_seccion_marcadores}><FaRegBookmark className="color-icon-web-map"/></button>
                      <button title="Leyenda" className="type_maps_web_button2" onClick={this.funcion_seccion_lista}><FaList className="color-icon-web-map"/></button>
                      <button title="Tipos de Mapas" className="type_maps_web_button3" onClick={this.funcion_seccion_mapas}><BsGrid className="color-icon-web-map"/></button>
                    </div>
                    
                    <div className={this.state.seccion_marcadores}> 
                      <div className="Header">
                        <h5 className="Titulo_cuadro_mapa">Marcadores</h5><button onClick={this.funcion_seccion_marcadores} title="Cerrar Cuadro" className="Header-close">X</button>
                      </div>                  
                      <div className="maps_type_web_markers">
                        <p className="departamentos" onClick={() => this.center_marker(-4.884022,-78.068801,7)}>Amazonas</p>
                        <p className="departamentos" onClick={() => this.center_marker(-9.5157265,-77.5592501,9)}>Áncash</p>
                        <p className="departamentos" onClick={() => this.center_marker(-14.030062,-72.9105147,14)}>Apurímac</p>
                        <p className="departamentos" onClick={() => this.center_marker(-16.3471731,-71.5747037,11)}>Arequipa</p>
                        <p className="departamentos" onClick={() => this.center_marker(-13.1583628,-74.2473238,12)}>Ayacucho</p>
                        <p className="departamentos" onClick={() => this.center_marker(-7.161997,-78.5069067,14)}>Cajamarca</p>
                        <p className="departamentos" onClick={() => this.center_marker(-13.5301354,-71.9639148,13)}>Cusco</p>
                        <p className="departamentos" onClick={() => this.center_marker(-12.0389338,-76.9602227,10)}>Lima</p>
                        
                      </div>
                    </div>
                    <div className={this.state.seccion_lista}> 
                      <div className="Header">
                        <h5 className="Titulo_cuadro_mapa">Leyenda</h5><button onClick={this.funcion_seccion_lista} title="Cerrar Cuadro" className="Header-close">X</button>
                      </div>                  
                      <p className="maps_type_web_leyenda">
                        <p className="leyendas" ><span className="simbolo_leyenda_t_masw" title="Icono para ensayo MASW">Ms</span> Ensayo MASW</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_mam" title="Icono para ensayo MAM">Ms</span> OTROS EnsayoS</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_microtremor" title="Icono para ensayo Microtremor">Ms</span> Ensayo Microtremor</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_perforacion" title="Icono para ensayo Perforación">Ms</span> Ensayo Perforación</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_zona1" title="Icono para Microzonificación Zona I">Ms</span> Microzonificación ZONA I</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_zona2" title="Icono para Microzonificación Zona II">Ms</span> Microzonificación ZONA II</p>
                        <p className="leyendas" ><span className="simbolo_leyenda_t_zona3" title="Icono para Microzonificación Zona III">Ms</span> Microzonificación ZONA III</p>
                      </p>
                    </div>
                    <div className={this.state.seccion_mapas}> 
                      <div className="Header">
                        <h5 className="Titulo_cuadro_mapa">Mapas</h5><button onClick={this.funcion_seccion_mapas} title="Cerrar Cuadro" className="Header-close">X</button>
                      </div>                  
                      <p className="maps_type_web">
                        <button onClick={this.toggleShowBM} title="Mapa Satelital" className="boton_satelital">Satelital</button>
                        <button onClick={this.toggleShowBMR} title="Mapa Vial" className="boton_road">Vial</button>
                        <button onClick={this.toggleShowWC} title="Mapa Fondo de Agua" className="boton_wc">Agua</button>
                        <button onClick={this.toggleShowOSM} title="Mapa Básico" className="boton_osm">OSM</button>
                        <button onClick={this.toggleShowByN} title="Mapa de Fondo Blanco y Negro" className="boton_byn">Binario</button>
                        <button onClick={this.toggleShowTerreno} title="Mapa Vista de Terreno" className="boton_terreno">Terreno</button>
                      </p>
                    </div>
                    
                  </div>
                  <div className="section_two_two">
                    <div className="Section_two_two_cantidades">
                      <div className="parte_arriba">
                        <div className="total_individual"><h6 className="t_t_i">MASW <span className="simbolo_leyenda_t_masw" title="Icono para ensayo MASW"  onClick={()=>this.cambiaTabla1(1)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_masw()}<br className="br_espacio"/></h5></div>
                        <div className="total_individual"><h6 className="t_t_i">MAM <span className="simbolo_leyenda_t_mam" title="Icono para OTROS ensayo"  onClick={()=>this.cambiaTabla1(2)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_mam()}<br className="br_espacio"/></h5></div>
                        <div className="total_individual"><h6 className="t_t_i">MICROTREMOR <span className="simbolo_leyenda_t_microtremor" title="Icono para ensayo MICROTREMORES" onClick={()=>this.cambiaTabla1(3)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_microtremor()}<br className="br_espacio"/></h5></div>
                        <div className="total_individual"><h6 className="t_t_i">PERFORACIÓN <span className="simbolo_leyenda_t_perforacion" title="Icono para ensayo PERFORACION"  onClick={()=>this.cambiaTabla1(4)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_perforacion()}<br className="br_espacio"/></h5></div>
                        <div className="total_individual"><h6 className="t_t_i">CALICATA <span className="simbolo_leyenda_t_CALICATA" title="Icono para ensayo CALICATA"  onClick={()=>this.cambiaTabla1(5)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_calicata()}<br className="br_espacio"/></h5></div>
                        <div className="total_individual"><h6 className="t_t_i">SPT <span className="simbolo_leyenda_t_SPT" title="Icono para ensayo SPT"  onClick={()=>this.cambiaTabla1(6)}>Ms</span></h6><h5 className="c_t_i">{this.cantidad_ensayos_spt()}<br className="br_espacio"/></h5></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section_three">
                  {/* <div class="table-responsive table-sm table-hover table-striped text-nowrap table-dark ensayos"> */}
                  <div class="ensayos">
                    <div className="Header_Ensayos">
                      <h4 className="titulo_ensayos"> Ensayos </h4><Button className="capasWeb" title="Capas de Ensayos Geofísicos"><FaLayerGroup className="color-icon-web"/></Button>
                    </div> 
                    <div className="Content_Ensayos">
                      <label className={this.state.text_ensayos_web}><button className="button_ensayos" type="checkbox" onClick={this.Check}/> MASW <small className="small_ensayos">{this.state.estado_capa_nombre_masw} </small><small className={this.state.estado_capa_masw}>ml</small></label>
                      <label className={this.state.text_ensayos_web_ma}><button className="button_ensayos" type="checkbox" onClick={this.Check1}/> MAM <small className="small_ensayos">{this.state.estado_capa_nombre_mam} </small><small className={this.state.estado_capa_mam}>ml</small></label>
                      <label className={this.state.text_ensayos_web_mi}><button className="button_ensayos" type="checkbox" onClick={this.Check2}/> MICROTREMOR <small className="small_ensayos">{this.state.estado_capa_nombre_microtremor} </small><small className={this.state.estado_capa_microtremor}>ml</small></label>
                      <label className={this.state.text_ensayos_web_pe}><button className="button_ensayos" type="checkbox" onClick={this.Check3}/> PERFORACIÓN <small className="small_ensayos">{this.state.estado_capa_nombre_perforacion} </small><small className={this.state.estado_capa_perforacion}>ml</small></label>
                      <label className={this.state.text_ensayos_web_er}><button className="button_ensayos" type="checkbox" onClick={this.Check9}/> ESPECTRO DE RESPUESTA <small className="small_ensayos">{this.state.estado_capa_nombre_espectro_respuesta} </small><small className={this.state.estado_capa_espectro_respuesta}>ml</small></label>
                      <label className={this.state.text_ensayos_web_ca}><button className="button_ensayos" type="checkbox" onClick={this.Check7}/> CALICATA <small className="small_ensayos">{this.state.estado_capa_nombre_calicata} </small><small className={this.state.estado_capa_calicata}>ml</small></label>
                      <label className={this.state.text_ensayos_web_sp}><button className="button_ensayos" type="checkbox" onClick={this.Check8}/> SPT <small className="small_ensayos">{this.state.estado_capa_nombre_spt} </small><small className={this.state.estado_capa_spt}>ml</small></label>
                    </div>  
                  </div> 
                  <div className="controls">
                    <div className="Header_Controls">
                      <h4 className="titulo_controls"> Controles </h4>
                    </div>
                    <div className="Content_Controls">
                      <button title="Geoposicionamiento" onClick={this.GeoPositionInMap} className="posicionamiento_web"><GiPositionMarker className="color-icon-web-controls"/></button>
                      <button title="Extender mapa" onClick={this.centrar_mapa} className="centrado_mapa_web"><GiUnionJack className="color-icon-web-controls"/></button>
                      <button title="Mapa Isoperiodos" onClick={this.mapa_isoperiodos} className="centrado_mapa_isoperiodos">Ip</button>
                      <button title="Mapa Microzonificación" onClick={this.mapa_microzonificacion} className="centrado_mapa_microzonificacion">Mz</button>
                      <button title="Restablecer filtros" onClick={this.reset_filtros} className="reset_filtro_externo_web"><FaRev className="color-icon-web-controls"/></button>
                      <button title="Activar / Desactivar capa de límites departamental" onClick={this.Check4} className="reset_filtro_externo_web"><BsLayersHalf className="color-icon-web-controls"/></button>
                      <button title="Activar / Desactivar capa de límites provincial" onClick={this.Check5} className="reset_filtro_externo_web"><BsFillLayersFill className="color-icon-web-controls"/></button>
                      <button title="Activar / Desactivar capa de límites distrital" onClick={this.Check6} className="reset_filtro_externo_web"><FiLayers className="color-icon-web-controls"/></button>
                    </div>
                  </div>
                  {/* <div className="filtros">
                    <div className="Header_Filtros">
                      <h4 className="titulo_filtros">Filtros</h4><Button title="Filtrar Ensayos" className="filtrar_puntos_web"><MdFilterList className="color-icon-web"/></Button>
                    </div>
                    <div className="Content_Filtros">
                      <form onSubmit={this.cuando_filtra}>
                        <label className="tipo_filtro_web">
                          <span className="filtrar_por">Filtrar por: </span>
                          <select value={this.state.tipo_filtro} onChange={this.cuando_cambia} className="select_box_web">
                            <option value="none">...</option>
                            <option value="departamento">Departamento</option>
                            <option value="provincia">Provincias</option>
                            <option value="distrito">Distritos</option>
                            <option value="vs">Vs</option>
                            <option value="periodo">Periodo</option>
                            <option value="area">Área</option>
                          </select>
                        </label>
                        <br/>
                        <label className={this.state.tipo_departamento}>
                          <span>Seleccione : </span>
                          <select value={this.state.departamento} onChange={this.cuando_cambia_departamento} className="select_box_web">
                            <option value="Amazonas">Amazonas</option><option value="Ancash">Áncash</option><option value="Apurimac">Apurímac</option>
                            <option value="Arequipa">Arequipa</option><option value="Ayacucho">Ayacucho</option><option value="Cajamarca">Cajamarca</option>
                            <option value="Callao">Callao</option><option value="Cusco">Cusco</option><option value="Huancavelica">Huancavelica</option>
                            <option value="Huanuco">Huánuco</option><option value="Ica">Ica</option><option value="Junin">Junin</option>
                            <option value="La Libertad">La Libertad</option><option value="Lambayeque">Lambayeque</option><option value="Lima">Lima</option>
                            <option value="Loreto">Loreto</option><option value="Madre de Dios">Madre de Dios</option><option value="Moquegua">Moquegua</option>
                            <option value="Pasco">Pasco</option><option value="Piura">Piura</option><option value="Puno">Puno</option>
                            <option value="San Martin">San Martín</option><option value="Tacna">Tacna</option><option value="Tumbes">Tumbes</option>
                            <option value="Ucayali">Ucayali</option>
                          </select>
                        </label>
                        
                        <label className={this.state.tipo_provincia}>
                          <span>Seleccione Provincia: </span>
                          <select value={this.state.provincia} onChange={this.cuando_cambia_provincia} className="select_box_web">
                            <option selected value="Lima">Lima (Dep.Lima)</option>
                            {this.contenido_filtros_provincias()}
                        
                          </select>
                        </label>
                        <label className={this.state.tipo_distrito}>
                          <span>Seleccione Distrito: </span>
                          <select value={this.state.distrito} onChange={this.cuando_cambia_distrito} className="select_box_web">
                            <option selected value="Ancón">Ancón (Dep.Lima)</option>
                            {this.contenido_filtros()}
                        
                          </select>
                        </label>
                        <label className={this.state.tipo_vs}>
                          <span>Seleccione Rango Vs: </span>
                          <select value={this.state.vs} onChange={this.cuando_cambia_vs} className="select_box_web">
                            <option value="0-200"> menor a 200 </option>
                            <option value="200-350"> 200-350 </option>
                            <option value="350-550"> 350-550 </option>
                            <option value="550-800"> 550-800 </option>
                            <option value="800-2000"> mayor a 800 </option>
                          </select>
                        </label>
                        <label className={this.state.tipo_periodo}>
                          <span>Seleccione Rango Periodo: </span>
                          <select value={this.state.periodo} onChange={this.cuando_cambia_periodo} className="select_box_web">
                            <option value="0-0.1"> menor a 0.1 seg </option>
                            <option value="0.1-0.25"> 0.1 - 0.25 seg </option>
                            <option value="0.25-0.4"> 0.25 - 0.4 seg </option>
                            <option value="0.40-10"> mayor a 0.40 seg </option>
                          </select>
                        </label>
                        <label className={this.state.tipo_radio}>
                          <span>Busca Dirección o Click en el Mapa: </span><br/>
                          <label className="label_coor">Latitud: <input className="coordenadas" onChange={this.cambia_lat_filter} value={this.state.lat_filter} type="text" ></input></label>
                          <label className="label_coor">Longitud: <input  className="coordenadas" onChange={this.cambia_lon_filter} value={this.state.lon_filter} type="text"></input></label>
                          <span>Selecciona Radio</span>
                          <select value={this.state.radio} onChange={this.cuando_cambia_radio} className="select_box_web">
                            <option value="1"> 1 Km </option>
                            <option value="2"> 2 Km </option>
                            <option value="5"> 5 Km </option>
                            <option value="10"> 10 Km </option>
                            <option value="25"> 25 Km </option>
                          </select>
                        </label>
    
                        <button className="boton_filtro_web" type="submit">Filtrar</button>
                        
                        </form>
                        
                    </div>
                  </div> */}
                </div>
                
                <div className={this.state.caracteristicas_puntos}>
                  <div className="encabezado_caracteristicas">
                    <span>Ensayo: </span>{this.state.tipo_ensayo} 
                    <button title="Cerrar ventana de datos" className="cerrar_caracteristicas" onClick={this.cerrar_caracteristicas}/>
                  </div>
                  <div className="contenido_caracteristicas">
                    <div className="Control_Detalle_Ensayo">
                      <button value="1" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton1}>Detalles</button>
                      <button value="2" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton2}>Imagenes</button>
                      <button value="3" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton3}>Comentario</button>
                    </div>
                    <div className={this.state.Contenido_Detalle_Ensayo}>
                      <table>
                        <tbody>
                          <tr><td><strong>Nombre: </strong></td><td>{this.state.nombre_ensayo}</td></tr>
                          <tr><td><strong>Departamento: </strong></td><td> {this.state.departamento_ensayo}</td></tr>
                          <tr><td><strong>Provincia: </strong></td><td> {this.state.provincia_ensayo}</td></tr>
                          <tr><td><strong>Distrito: </strong></td><td> {this.state.distrito_ensayo}</td></tr>
                          <tr><td><strong>Zona: </strong></td><td> {this.state.zona_ensayo}</td></tr>
                          <tr><td><strong>Coordenadas (UTM): </strong></td><td> {Number(this.state.coordUTM1_ensayo).toFixed(2)}	{Number(this.state.coordUTM2_ensayo).toFixed(2)}</td></tr>
                          <tr><td><strong>Fuente: </strong></td><td> {this.state.fuente_ensayo}</td></tr>
                          <tr><td><strong>Nombre Ini.: </strong></td><td> {this.state.name_ini}</td></tr>
                          
                          {this.dinamic_detail()}
                          <tr><td><strong>Proyecto: </strong></td><td> {this.state.proyecto}</td></tr>
                          <tr><td><strong>Simbolo: </strong></td><td> <span className={this.state.simbolo_ensayo}>Ms</span></td></tr>
                        </tbody>
                      </table>
    
                    </div>
                    <div className={this.state.Contenido_Imagenes_Ensayo}>
                      <BanerDetail ruta_img_1={this.state.img1_ensayo} ruta_img_2={this.state.img2_ensayo} ruta_img_3={this.state.img3_ensayo}/>
                      {/* <a href="./logo192.png" target='_blank' ><img
                        className=""
                        src="./logo192.png" alt="logo" /></a> */}
                    </div>
                    <div className={this.state.Contenido_Documentacion_Ensayo}>
                      <p className="comentario">{this.state.documentacion_ensayo}</p>
                    </div>
                  </div>
                  <div className="footer_caracteristicas">
                    <button title="Acercarse para ver el punto" className="acercarse_caracteristicas" onClick={this.hacer_zoom}/>
                    <button title="Centrar Mapa" className="centrar_caracteristicas" onClick={this.centrar_mapa}/>
                    
                  </div>
                </div>
    
                <div className={this.state.panel_filtros}>
                  <div className="encabezado_filtros">
                    <span>Filtrar Ensayos: </span>
                    <button title="Cerrar ventana de filtros" className="cerrar_filtros" onClick={this.cerrar_filtros}/>
                    <button title="Resetear filtros usados" className="reset_filtro" onClick={this.reset_filtros}/>
                  </div>
                  <div className="contenido_filtros">
                    <form onSubmit={this.cuando_filtra}>
                      <label className="tipo_filtro">
                         Selecciona Filtro: <select value={this.state.tipo_filtro} onChange={this.cuando_cambia} className="select_box">
                          <option value="none">...</option>
                          <option value="departamento">Departamento</option>
                          <option value="ciudad">Ciudad</option>
                          <option value="ano">Año</option>
                          <option value="fuente">Fuente</option>
                        </select>
                      </label>
                      <br/>
                      <label className={this.state.tipo_departamento}>
                        Seleccione Departamento: <select value={this.state.departamento} onChange={this.cuando_cambia_departamento} className="select_box">
                          <option value="amazonas">Amazonas</option><option value="ancash">Áncash</option><option value="apurimac">Apurímac</option>
                          <option value="arequipa">Arequipa</option><option value="ayacucho">Ayacucho</option><option value="cajamarca">Cajamarca</option>
                          <option value="callao">Callao</option><option value="cuzco">Cuzco</option><option value="huancavelica">Huancavelica</option>
                          <option value="huanuco">Huánuco</option><option value="ica">Ica</option><option value="junin">Junin</option>
                          <option value="libertad">La Libertad</option><option value="lambayeque">Lambayeque</option><option value="Lima">Lima</option>
                          <option value="loreto">Loreto</option><option value="Madre de Dios">Madre de Dios</option><option value="moquegua">Moquegua</option>
                          <option value="pasco">Pasco</option><option value="piura">Piura</option><option value="puno">Puno</option>
                          <option value="sanmartin">San Martín</option><option value="Tacna">Tacna</option><option value="Tumbes">Tumbes</option>
                          <option value="ucayali">Ucayali</option>
    
                        </select>
                      </label>
                      <label className={this.state.tipo_ciudad}>
                        Seleccione Ciudad: <select value={this.state.ciudad} onChange={this.cuando_cambia_ciudad} className="select_box">
                          <option value="amazonas">Amazonas</option><option value="ancash">Áncash</option><option value="apurimac">Apurímac</option>
                          <option value="arequipa">Arequipa</option><option value="ayacucho">Ayacucho</option><option value="cajamarca">Cajamarca</option>
                          <option value="callao">Callao</option><option value="cuzco">Cuzco</option><option value="huancavelica">Huancavelica</option>
                          <option value="huanuco">Huánuco</option><option value="ica">Ica</option><option value="junin">Junin</option>
                          <option value="libertad">La Libertad</option><option value="lambayeque">Lambayeque</option><option value="Lima">Lima</option>
                          <option value="loreto">Loreto</option><option value="Madre de Dios">Madre de Dios</option><option value="moquegua">Moquegua</option>
                          <option value="pasco">Pasco</option><option value="piura">Piura</option><option value="puno">Puno</option>
                          <option value="sanmartin">San Martín</option><option value="Tacna">Tacna</option><option value="Tumbes">Tumbes</option>
                          <option value="ucayali">Ucayali</option>
    
                        </select>
                      </label>
                      <label className={this.state.tipo_ano}>
                        Seleccione Año: <select value={this.state.ano} onChange={this.cuando_cambia_ano} className="select_box">
                          <option value="2020">2020</option><option value="2019">2019</option><option value="2018">2018</option>
                          <option value="2017">2017</option><option value="2016">2016</option><option value="2015">2015</option>
                          <option value="2014">2014</option><option value="2013">2013</option><option value="2012">2012</option>
                          <option value="2011">2011</option><option value="2010">2010</option><option value="2009">2009</option>
                          <option value="2008">2008</option><option value="2007">2007</option><option value="2006">2006</option>
                          <option value="2005">2005</option><option value="2004">2004</option><option value="2003">2003</option>
                          <option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option>
                          <option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option>
                          <option value="1996">1996</option>
    
                        </select>
                      </label>
                      <label className={this.state.tipo_fuente}>
                        Seleccione Fuente: <select value={this.state.fuente} onChange={this.cuando_cambia_fuente} className="select_box">
                          <option value="igp">IGP</option><option value="cismid">CISMID</option><option value="redacelerografica">RED ACELEROGRÁFICA</option>
                          <option value="sencico">SENCICO</option><option value="municipios">MUNICIPIOS</option><option value="otros">OTROS</option>
                        </select>
                      </label>
                      <button className="boton_filtro" type="submit">Filtrar</button>
                      
                      </form>
                      
                  </div>
                </div>
              </div>
            );
        }

}