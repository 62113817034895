import React, { Component, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

export class Error404 extends Component{
    render(){
        return(

        <div className="container-lg">
            <div className="row">
                <div className="col-md-12 py-2">
                    <h5 width="100" className=""> Sección en construcción o no disponible</h5>
                    <img src="https://www.somosexitocreativo.com/img/Sin%20t%C3%ADtulo-2.png" width="90%"/>
                </div>
            </div>
        </div>
        )
    }
}