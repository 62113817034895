import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import {MDBNavLink, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter} from 'mdbreact';
//externals
import ReactDOM from 'react-dom';
import React from 'react';
//Stilos

export default class ModalCaracteristicas extends React.Component {
    state = {
        modal_caracteristicas: true
      }
      
      toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
          [modalNumber]: !this.state[modalNumber]
        });
      }
    render(){
        return(
            <MDBModal isOpen={this.state.modal_caracteristicas} toggle={this.toggle('_caracteristicas')} className="modal-controles1" backdrop={true} >
                <MDBModalHeader toggle={this.toggle('_caracteristicas')} className="docu">Ensayos Geofísicos</MDBModalHeader>
                <MDBModalBody className="docu">
                  <p>Capas de Ensayos</p>
                  <button onClick={this.toggleDataActive}> 
                      Activar Polígono
                  </button>
                  <button onClick={this.toggleDataInactive}> 
                      Desactivar Polígono
                  </button>
                </MDBModalBody>
                <MDBModalFooter>
                    <button toggle={this.toggle('_caracteristicas')} className="primary">
                        Cerrar
                    </button>
                </MDBModalFooter>
              </MDBModal>
        );
    }

}
