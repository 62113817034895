import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

export class Inicio extends Component{
    render(){
        return(
            <div>Inicio</div>
        )
    }
}