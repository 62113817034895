import React from 'react';
import { MDBNavLink } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import "./banner2.css"

//Stilos

export default class BanerDetail extends React.Component {
    render(){
        return(
            <div className="carrusel_Detail">
            <Carousel className="banner_Detail" interval={null} indicators={false} nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" style={{ color: 'blue' }} />} prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" style={{ color: 'blue' }} />} >
                <Carousel.Item>
                    <img
                    className="img_detail"
                    src={this.props.ruta_img_1}
                    />
                    <Carousel.Caption  className="texto">
                    <a href={this.props.ruta_img_1} className="boton_ver_detalle" target="blank" variant="dark">Ver</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="img_detail"
                    src={this.props.ruta_img_2}
                    />
                    <Carousel.Caption className="texto">
                    <a href={this.props.ruta_img_2} className="boton_ver_detalle" target="blank"  variant="dark">Ver</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="img_detail"
                    src={this.props.ruta_img_3}
                    />
                    <Carousel.Caption  className="texto">
                    <a href={this.props.ruta_img_3} className="boton_ver_detalle" target="blank" variant="dark">Ver</a>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            </div>
        );
    }
}
